/* eslint-disable react/no-danger */
import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import * as moment from 'moment';
import Img from 'gatsby-image';
import UserIcon from 'assets/svg/user.inline.svg';
import CalenderIcon from 'assets/svg/calender.inline.svg';
import ReactPaginate from 'react-paginate';
import { Layout } from 'components';
import { normalizePath } from 'utils/get-url-path';
import info from '../data.json';

const newpageTitle = info.filter((item: any) => {
  return item.pagetitle === 'Article Page';
});
export default ({ data, pageContext }: any) =>
  !!data.allWpPost && !!data.allWpPost.nodes ? (
    <Layout
      pageTitle={newpageTitle[0].title}
      description={newpageTitle[0].description}
      image={newpageTitle[0].image}
    >
      {data.allWpPost.nodes.map(post => (
        <div className="articles-page">
          <div className="articles-page-item" key={post.link}>
            <div className="articles-page-image">
              <Link to={normalizePath(post.uri)}>
                {post?.featuredImage && (
                  <Img
                    fluid={
                      post.featuredImage.node.localFile.childImageSharp.fluid
                    }
                  />
                )}
              </Link>
            </div>
            <div className="articles-page-content">
              <div>
                <h5 className="articles-page-title">{post.title}</h5>
                <div className="articles-page-details">
                  <span>
                    <UserIcon /> By {post.author.node.name}
                  </span>
                  <span>
                    <CalenderIcon /> {moment(post.date).format('Do MMMM YYYY')}
                  </span>
                </div>
                {!!post.content && (
                  <p
                    className="articles-page-summary"
                    dangerouslySetInnerHTML={{
                      __html: post.content.substring(0, 250),
                    }}
                  />
                )}
              </div>
              <div className="latest-articles-button">
                <Link to={normalizePath(post.uri)}>
                  <button type="button" className="articles-page-link">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}

      {pageContext && pageContext.totalPages > 1 && (
        <div>
          <ReactPaginate
            previousLabel={
              pageContext?.page !== 1 && <div className="previous-page" />
            }
            nextLabel={
              pageContext?.totalPages !== pageContext.page && (
                <div className="next-page" />
              )
            }
            onPageChange={({ selected }) => {
              const page = selected + 1;
              const path = page === 1 ? `/blog/` : `/blog/${page}/`;
              navigate(path);
            }}
            disableInitialCallback
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageContext.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            initialPage={pageContext.page - 1}
          />
        </div>
      )}
    </Layout>
  ) : null;

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query HomePage($offset: Int!, $perPage: Int!) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: { nodeType: { in: ["Post", "Page", "Alot"] } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        categories {
          nodes {
            name
          }
        }
        uri
        title
        date
        content
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
